<template>
  <div>
    <BaseModal
      name="product-new"
      size="lg"
      title="📂 Criação de Produto"
      @hidden="clearFields"
      @shown="fetchUserSettings"
    >
      <section>
        <b-form novalidate>
          <b-overlay
            :show="loading"
            spinner-variant="dark"
            class="container-form"
          >
            <div class="input-upload">
              <b-col cols="12" md="4">
                <b-form-group label="">
                  <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="product.images"
                    idUpload="myIdUpload"
                    editUpload="myIdEdit"
                    dragText="120px por 120px"
                    browseText="Procurar imagens"
                    :showPrimary="false"
                    :showEdit="false"
                  ></vue-upload-multiple-image>

                  <b-form-invalid-feedback
                    :state="!(product.images.length == 0 && submit)"
                  >
                    Por favor, insira pelo menos uma imagem.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </div>
            <div class="form-produto">
              <b-form-group label="Título do Produto" label-for="name">
                <b-form-input
                  id="name"
                  name="name"
                  placeholder="Título curto, ex: Curso de Marketing 2.0"
                  v-model="product.name"
                  type="text"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  Por favor, insira o nome do produto.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="descricao-container"
                label="Descrição do Produto"
                label-for="description"
              >
                <div
                  class="min-descricao"
                  :class="{ ok: product.description.length >= 200 }"
                >
                  {{ product.description.length }}
                </div>

                <!-- v-model="product.description" -->
                <b-form-textarea
                  id="description"
                  name="description"
                  v-model="product.description"
                  v-validate="'required|min:200'"
                  placeholder="Uma breve descrição do produto/serviço, benefícios e etc"
                ></b-form-textarea>
                <b-form-invalid-feedback :state="!errors.has('description')">
                  O campo descrição deve ter pelo menos 200 caracteres
                </b-form-invalid-feedback>
                <div class="mark-down mt-2">
                  <p class="info-mark">Descrição formatada em MarkDown</p>
                  <a
                    href="#"
                    class="info-mark open-mark"
                    @click.prevent="openModal('editor-markdown')"
                    >Formatar descrição</a
                  >
                </div>
              </b-form-group>

              <b-form-group label="Página de Vendas" label-for="sales_page">
                <b-form-input
                  id="sales_page"
                  name="sales_page"
                  placeholder="Insira a Página de Vendas "
                  v-model="product.sales_page"
                  type="text"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('sales_page')">
                  Por favor, insira a página de vendas
                </b-form-invalid-feedback>
                <div class="mark-down mt-2">
                  <p class="info-mark">
                    Caso não tenha uma página de vendas, informe o Instagram,
                    Facebook ou afins onde será vendido o produto para
                    conhecermos melhor o seu trabalho até que tenha página de
                    vendas.
                  </p>
                </div>
              </b-form-group>

              <div class="flex-row">
                <b-col cols="12">
                  <b-form-group label="Tipo do Produto" label-for="format">
                    <b-form-select
                      id="format"
                      name="format"
                      v-model="product.format"
                      v-validate="'required'"
                      :options="formats_product"
                      @change="changeFormatProduct"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="!errors.has('format')">
                      Por favor, selecione o tipo do produto.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </div>

              <div
                class="flex-row align-items-center"
                v-if="product.format == 'PHYSICALPRODUCT'"
                data-anima="top"
              >
                <b-col cols="12" md="7">
                  <b-form-group label="" label-for="has_stock ">
                    <b-form-checkbox
                      v-model="product.has_stock"
                      name="has_stock"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        Produto tem estoque?
                        <span class="descricao-checkbox"
                          >Caso habilitado, iremos controlar a quantidade de
                          vendas com base no seu estoque</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="5">
                  <b-form-group
                    label="Qtd em estoque"
                    label-for="stock"
                    v-if="product.has_stock"
                    data-anima="top"
                  >
                    <b-form-input
                      id="stock"
                      placeholder="30"
                      name="stock"
                      v-model="product.stock"
                      v-validate="'required|numeric|min_value:1'"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-invalid-feedback :state="!errors.has('stock')">
                    Mínimo 1 produto em estoque.
                  </b-form-invalid-feedback>
                </b-col>
              </div>

              <div class="flex-row">
                <b-col cols="12" md="5">
                  <b-form-group label="Categoria" label-for="category">
                    <b-form-select
                      id="category"
                      name="category"
                      v-model="product.category_id"
                      v-validate="'required'"
                      :options="categories"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="!errors.has('category')">
                      Por favor, insira a categoria do produto.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Garantia (dias)" label-for="warranty">
                    <b-form-input
                      id="warranty"
                      placeholder="30"
                      name="warranty"
                      v-model="product.warranty"
                      v-mask="'###'"
                      v-validate="'required|numeric|min_value:7'"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-invalid-feedback :state="!errors.has('warranty')">
                    Mínimo de 7 dias de garantia.
                  </b-form-invalid-feedback>
                </b-col>
              </div>
              <div class="flex-row">
                <b-col cols="12" md="5">
                  <b-form-group label="Tipo de Cobrança" label-for="type">
                    <b-form-select
                      id="type"
                      name="type"
                      v-model="product.type"
                      v-validate="'required'"
                      :options="types"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="!errors.has('type')">
                      Por favor, insira o tipo do produto.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Preço" label-for="amount">
                    <money
                      id="amount"
                      v-model="product.amount"
                      v-bind="money"
                    ></money>
                  </b-form-group>
                </b-col>
              </div>

              <div class="flex-row">
                <b-col
                  cols="12"
                  md="5"
                  v-if="product.type == 'SUBSCRIPTION'"
                  data-anima="top"
                >
                  <b-form-group label="Período" label-for="period">
                    <b-form-select
                      id="period"
                      name="period"
                      v-model="product.period"
                      v-validate="'alpha_num'"
                      :options="periods"
                    ></b-form-select>

                    <b-form-invalid-feedback :state="!errors.has('period')">
                      Por favor, insira o valor em números.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                  v-if="product.type == 'SUBSCRIPTION'"
                  data-anima="top"
                >
                  <b-form-group label="Avaliação (opcional)" label-for="trial">
                    <b-form-input
                      id="trial"
                      name="trial"
                      v-model="product.trial"
                      type="number"
                      placeholder="Período de Avaliação. Ex: 7"
                      v-validate="'alpha_num'"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('trial')">
                      Por favor, insira o valor em números.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </div>
              <b-col
                cols="12"
                md="6"
                data-anima="left"
                v-if="product.period == 'CUSTOM'"
              >
                <b-form-group
                  label="Novo período (em dias)"
                  label-for="custom_period"
                >
                  <b-form-input
                    name="custom_period"
                    v-model="custom_period"
                    type="number"
                    placeholder="Período em dias. Ex: 730 "
                    v-validate="'required|alpha_num'"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('custom_period')"
                  >
                    Por favor, digite o período personalizado
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="5" v-if="product.type == 'SUBSCRIPTION'">
                <b-form-group label="Limite de cobranças" label-for="charges">
                  <b-form-input
                    name="charges"
                    v-model="product.charges"
                    type="number"
                    placeholder="Ex: 15 "
                    v-validate="'alpha_num'"
                  ></b-form-input>

                  <div
                    class="mark-down mt-2"
                    v-if="product.charges"
                    data-anima="top"
                  >
                    <p class="info-mark">
                      Essa assinatura será cobrada {{ product.charges }}x e
                      depois será encerrada automaticamente
                    </p>
                  </div>
                </b-form-group>
              </b-col>
            </div>
          </b-overlay>
        </b-form>
      </section>

      <template v-slot:footer="{ cancel }">
        <BaseButton
          :disabled="loading"
          variant="secondary_outline"
          @click="cancel"
          class="mr-4"
        >
          Cancelar
        </BaseButton>
        <BaseButton :disabled="loading" variant="secondary" @click="onSubmit">
          Criar Produto
        </BaseButton>
      </template>
    </BaseModal>

    <!-- --- modal integrações -->
    <BaseModal
      name="modal-info-aprovacao"
      size="lg"
      :title="infos_approval.title"
      @hidden="fetchProducts"
    >
      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div>
        <div class="aprovacao-header">
          <div class="image-approval" v-if="approval_product.images.length">
            <b-img
              class="foto-produto"
              :src="approval_product.images[0].path"
            ></b-img>
          </div>
          <div v-else class="foto-produto thumb-fake">•••</div>
          <div class="infos-aprovacao">
            <span>Dados do produto</span>
            <h5>{{ approval_product.name }}</h5>
            <p>R$ {{ formatMoney(+approval_product.amount) }}</p>

            <div class="info-produto mt-3" v-if="approval_product.id">
              <span>Link para divulgação</span>
              <div class="d-flex align-items-center">
                <a :href="baseURL + '/' + approval_product.id" target="_blank"
                  >{{ baseURL + "/" + approval_product.id }}
                </a>
              </div>
              <div class="_aprovacao btn-2" v-if="!approval_product.pending">
                <img src="@/assets/img/icons/info-prod.svg" />
                Produto em Processo de Análise
              </div>
            </div>
          </div>
        </div>

        <div class="aprovacao-content">
          <h6>{{ infos_approval.body_title }}</h6>
          <p>
            {{ infos_approval.description }}
          </p>
          <div class="aprovacao-acoes">
            <BaseButton
              variant="link-info"
              class="btn-acao"
              @click="voltarProdutos"
            >
              Ver meus produtos
            </BaseButton>

            <BaseButton
              variant="secondary_outline"
              class="btn-acao"
              @click="editProduct(approval_product.id)"
            >
              Continuar editando o produto
            </BaseButton>
          </div>
        </div>
      </div>
    </BaseModal>

    <EditorMarkDown :text_parent="product.description" from="new" />
  </div>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import ProductService from "@/services/resources/ProductService";
import CategoryService from "@/services/resources/CategoryService";
import EditorMarkDown from "@/components/Seller/Products/modals/EditorMarkDown";
import UserSettingsService from "@/services/resources/UserSettingsService";
const serviceUserSettings = UserSettingsService.build();

const serviceProduct = ProductService.build();
const serviceCategory = CategoryService.build();

import { Money } from "v-money";
import FormatMoney from "@/mixins/money";
import { EventBus } from "@/main.js";

export default {
  mixins: [FormatMoney],
  components: {
    Money,
    VueUploadMultipleImage,
    EditorMarkDown,
  },

  data() {
    return {
      product: {
        name: null,
        description: "",
        amount: 0,
        charges: null,
        type: null,
        format: null,
        banner: [],
        period: 30,
        trial: null,
        has_stock: false,
        stock: 0,
        category_id: null,
        warranty: null,
        warranty_checkout: true,
        method: "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS",
        allowed_coupon: false,
        is_checkout_address: false,
        allow_proposal: true,
        affiliation: 0,
        affiliation_approbation: 0,
        affiliation_public: 0,
        affiliate_proposal: 0,
        comission: null,
        sales_page: null,
        thank_you_page: null,
        url_callback: null,
        bump_product_id: null,
        proposal_minimum: null,
        release: {},
        images: [],
        metas: [],
        pixels: [],
        co_sellers: [],
      },
      approval_product: {
        name: null,
        description: "",
        amount: 0,
        type: null,
        format: null,
        banner: [],
        period: 30,
        trial: null,
        has_stock: false,
        stock: 0,
        category_id: null,
        warranty: null,
        method: "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS",
        allowed_coupon: false,
        is_checkout_address: false,
        allow_proposal: true,
        affiliation: false,
        affiliation_approbation: false,
        affiliation_public: false,
        affiliate_proposal: false,
        comission: null,
        sales_page: null,
        thank_you_page: null,
        url_callback: null,
        bump_product_id: null,
        proposal_minimum: null,
        release: {},
        images: [],
        metas: [],
        pixels: [],
        co_sellers: [],
      },
      infos_approval: {
        title: "✔️ Seu produto está quase pronto para vender",
        body_title: "✋ Produto aguardando aprovação",
        description:
          "Seu produto está em analise e após aprovação estará pronto para ser vendido, mas você pode continuar editando as configurações do seu produto.",
      },
      types: [
        { value: null, text: "Selecione o tipo" },
        { value: "TRANSACTION", text: "Valor único" },
        { value: "SUBSCRIPTION", text: "Assinatura" },
      ],
      periods: [
        { value: 30, text: "Mensal" },
        { value: 90, text: "Trimestral" },
        { value: 180, text: "Semestral" },
        { value: 365, text: "Anual" },
      ],
      custom_period: null,
      permite_periodo_personalizado: false,
      methods: [
        { name: "Todos os Métodos", value: "ALL" },
        { name: "Cartão de crédito", value: "CREDIT_CARD" },
        { name: "Boleto", value: "BOLETO" },
        { name: "Pix", value: "PIX" },
        { name: "PayPal", value: "PAYPAL" },
        { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
      ],
      products: [],
      categories: [],
      formats_product: [
        { value: null, text: "Selecione o tipo", disabled: true },
        { value: "INFOPRODUCT", text: "InfoProduto" },
        { value: "SERVICE", text: "Serviço" },
        { value: "PHYSICALPRODUCT", text: "Produto Físico" },
        { value: "EVENT", text: "Evento" },
        { value: "OTHERS", text: "Outros" },
      ],
      loading: false,
      submit: false,
      slug: null,
      link_afiliado: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
  },

  methods: {
    changeFormatProduct(e) {
      if (e == "PHYSICALPRODUCT") {
        this.types = [
          { value: null, text: "Selecione o tipo" },
          { value: "TRANSACTION", text: "Valor único" },
          {
            value: "SUBSCRIPTION",
            text: "Assinatura (apenas para produtos digitais)",
            disabled: true,
          },
        ];
      } else {
        this.types = [
          { value: null, text: "Selecione o tipo" },
          { value: "TRANSACTION", text: "Valor único" },
          { value: "SUBSCRIPTION", text: "Assinatura" },
        ];
      }
    },

    loadProduct(slug_response) {
      this.loading = true;
      if (slug_response) {
        let data = {
          id: slug_response,
        };

        serviceProduct
          .read(data)
          .then((response) => {
            this.approval_product = response.data;

            document.querySelector("body").classList.remove("modal-open");
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },

    voltarProdutos() {
      this.fetchProducts();
      this.$bvModal.hide("modal-info-aprovacao");
    },
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    editProduct(id_product) {
      this.$router.push(`/produtos/edicao-produto/${id_product}`);
      this.$bvModal.hide("modal-info-aprovacao");
    },

    onSubmit() {
      this.submit = true;

      this.$validator.validateAll().then(async (result) => {
        if (result && this.product.images.length > 0) {
          if (this.product.amount < 10) {
            this.$bvToast.toast(
              "Preço mínimo do produto é de R$ 10,00. Preecha corretamente",
              {
                title: "Produto",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            if (document.querySelector("#amount")) {
              document.querySelector("#amount").focus();
            }
            this.submit = false;
            return false;
          }

          if (this.product.warranty < 7) {
            this.$bvToast.toast(
              "Prazo de reembolso deve ser maior do que 7 dias. Preecha corretamente",
              {
                title: "Produto",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.submit = false;
            return;
          }

          this.loading = true;

          if (this.product.affiliation) {
            this.product.comission = parseFloat(
              String(this.product.comission).replace(",", ".")
            );
          }

          if (this.product.proposal_minimum) {
            this.product.proposal_minimum = parseFloat(
              String(this.product.proposal_minimum).replace(",", ".")
            );
          }

          if (
            !this.product.trial ||
            this.product.trial.length == 0 ||
            this.product.type == "TRANSACTION"
          ) {
            this.product.trial = null;
          }

          if (this.product.format == "PHYSICALPRODUCT") {
            this.product.is_checkout_address = true;
          } else {
            this.product.is_checkout_address = false;
          }
          if (this.product.period == "CUSTOM") {
            this.product.period = +this.custom_period;
          }

          if (this.product.type == "SUBSCRIPTION" && this.product.charges) {
            this.product.charges = +this.product.charges;
          } else {
            this.product.charges = null;
          }

          if (this.product.type == "SUBSCRIPTION") {
            this.product.method = "CREDIT_CARD,BOLETO";
          }

          //Criando produto
          serviceProduct
            .create(this.product)
            .then((response) => {
              this.loadProduct(response.slug);
              this.loading = false;
              this.submit = false;

              this.clearFields();

              this.$bvModal.hide("product-new");

              //Abrir modal Informando sobre o processo de aprovação
              // if (response.pending) {
              if (
                response.status == "REQUESTED" ||
                response.status == "PENDING"
              ) {
                this.$bvToast.toast(
                  "Produto foi enviado para aprovação. Você receberá a resposta da análise por email",
                  {
                    title: "Produto",
                    variant: "info",
                    autoHideDelay: 5000,
                    noAutoHide: true,
                    appendToast: true,
                  }
                );

                this.infos_approval.title =
                  "✔️ Seu produto está quase pronto para vender";
                this.infos_approval.body_title =
                  "✋ Produto aguardando aprovação";
                this.infos_approval.description = `Seu produto está em analise e após aprovação estará pronto para ser vendido, mas você pode continuar editando as configurações do seu produto.`;
              } else if (response.status == "APPROVED") {
                this.$bvToast.toast("Produto criado com sucesso.", {
                  title: "Produto",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });

                this.infos_approval.title =
                  "🎉 Seu produto está pronto para vender";
                this.infos_approval.body_title = "E você pode fazer muito mais";
                this.infos_approval.description = `Agora que seu produto está pronto para ser vendido você pode habilitar recursos avançados para te ajudar a vendas ainda mais.`;
              }
              this.openModal("modal-info-aprovacao");

              this.$emit("close");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              this.submit = false;
            });
        }
      });
    },

    clearFields() {
      this.product = {
        name: null,
        description: "",
        has_stock: false,
        stock: 0,
        type: null,
        period: 30,
        trial: null,
        amount: 0,
        category_id: null,
        affiliation: false,
        allow_proposal: false,
        affiliation_approbation: false,
        affiliation_public: false,
        comission: null,
        sales_page: null,
        thank_you_page: null,
        images: [],
        metas: [],
        pixels: [],
      };
      this.slug = null;
    },

    fetchCategories() {
      this.categories = [
        { value: null, text: "Selecione a categoria", disabled: true },
      ];

      serviceCategory
        .search()
        .then((response) => {
          response.forEach((element) => {
            this.categories.push({ value: element.id, text: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.submit = false;
        });
    },
    fetchProducts() {
      this.products = [];

      serviceProduct
        .search()
        .then((response) => {
          response.data.forEach((element) => {
            this.products.push({ value: element.id, text: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadImageSuccess(formData, index, fileList) {
      this.product.images = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        this.product.images.splice(index, 1);
        done();
      }
    },
    editImage(formData, index, fileList) {
      this.product.images = fileList;
    },
    uploadImageSuccessBannerCheckout(formData, index, fileList) {
      this.product.banner = fileList;
    },
    beforeRemoveBannerCheckout(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        done();
      }
    },
    editImageBannerCheckout(formData, index, fileList) {
      this.product.banner = fileList;
    },
    fetchUserSettings() {
      let data = {
        id: this.userId || JSON.parse(localStorage.user).id,
      };

      serviceUserSettings
        .read(data)
        .then((response) => {
          response.forEach((item) => {
            if (item.key == "PRODUCT_CUSTOM_PERIOD" && item.value == "1") {
              this.permite_periodo_personalizado = true;
            }
          });
        })
        .finally(() => {
          if (this.permite_periodo_personalizado) {
            this.periods.push({ value: "CUSTOM", text: "Personalizado" });
          }
        });
    },
  },
  mounted() {
    this.fetchCategories();
    EventBus.$on("textNewMarkDown", (data) => {
      this.product.description = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}

.container-form {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
}
.input-upload {
  // width: 190px;
  // height: 180px;
  // background: red;
  margin-top: 20px;
}
.form-produto {
  width: 100%;
  // background: silver;
}
.btn-more {
  background: rgba(0, 35, 99, 0.2);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  outline: none;
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.aprovacao-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  margin: 20px 0 40px 0;
}
.infos-aprovacao {
  margin: 20px 0;
}
.infos-aprovacao span {
  font-size: 13px;
  color: #81858e;
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}
.infos-aprovacao a {
  font-size: 15px;
  color: #2a63ab;
  font-weight: normal;
}

.infos-aprovacao h5 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 5px;
}
.infos-aprovacao p {
  font-weight: 600;
  color: #2a63ab;
}
.image-approval {
  width: 230px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  background: #f5f2fc;
}
.aprovacao-content h6 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
}
.aprovacao-content p {
  font-size: 15px;
  color: #81858e;
  font-weight: normal;
  line-height: 1.5;
}
.aprovacao-acoes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 40px 0 20px 0;
}
@media screen and (max-width: 768px) {
  .aprovacao-content h6 {
    font-size: 15px;
  }
  .aprovacao-content p {
    font-size: 14px;
  }
  .aprovacao-acoes {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.copy-link {
  width: 15px;
  /* margin-left: 20px; */
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
.copy-link:hover {
  filter: invert(0);
}
.thumb-fake {
  width: 100%;
  color: silver;
  font-size: 32px;
  letter-spacing: 2px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .container-form {
    display: block;
  }
  .flex-row {
    display: block;
  }
}

.v-money {
  width: 100%;
}

.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
._aprovacao {
  display: flex;
  align-items: center;
  max-width: 260px;
  margin-top: 20px;
}
._aprovacao img {
  margin-right: 10px;
}
#description {
  height: 18vh !important;
  resize: none;
  font-size: 13px !important;
}
.mark-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-mark {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}
.open-mark {
  color: #2a63ab;
  font-weight: 600;
}
.descricao-container {
  position: relative;
}
.min-descricao {
  min-width: 30px;
  min-height: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0c37;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: 0;
}
.min-descricao.ok {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.2);
}
.info-checkbox {
  cursor: pointer;
  font-weight: 600;
  color: #333;
}
</style>
