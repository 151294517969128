<template>
  <BaseModal
    name="editor-markdown"
    size="xl"
    title="Formate aqui o seu texto"
    @shown="openModal"
    @hidden="reset"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <section v-else>
      <div class="text-right mb-2">
        <BaseButton
          :disabled="loading"
          variant="secondary_outline"
          @click="sendTextEdition"
          class="btn-top"
        >
          Confirmar
        </BaseButton>
      </div>

      <v-md-editor
        v-model="text"
        :toolbar="toolbar"
        placeholder="Digite e edite aqui o seu texto"
        height="500px"
      ></v-md-editor>
    </section>
    <template v-slot:footer="">
      <BaseButton
        :disabled="loading"
        variant="secondary_outline"
        @click="sendTextEdition"
      >
        Confirmar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: "EditorMarkDown",

  data() {
    return {
      loading: false,
      text: "",
      toolbar: {
        save: false,
        toc: false,
        fullscreen: false,
      },
    };
  },
  props: {
    text_parent: {
      type: String,
      default: "",
    },
    from: {
      type: String,
    },
  },

  methods: {
    sendTextEdition() {
      if (this.from == "edit") {
        EventBus.$emit("textEditMarkDown", this.text);
      } else if (this.from == "new") {
        EventBus.$emit("textNewMarkDown", this.text);
      }
      this.$bvModal.hide("editor-markdown");
    },
    reset() {
      this.text = "";
    },
    openModal() {
      this.text = this.text_parent;
    },
  },
};
</script>

<style scoped>
.btn-top {
  position: absolute;
  top: -65px;
  right: 0;
}
</style>
